import React from "react"
import Page from "../common/components/Page"
// import PressKitMediaLibrary from "../presskit/components/PressKitMediaLibrary"

const PressKitPage = () => {
  return (
    <Page
      seo={{
        title: "Press Kit",
        description: "A media library to support press and ambassadors.",
      }}
    >
      <Page.Header downArrows>
        <Page.Header.Title>Press Kit</Page.Header.Title>
        <Page.Header.Subtitle>
          Almagesto High-Res Media Library
        </Page.Header.Subtitle>
      </Page.Header>
      {/* <PressKitMediaLibrary /> */}
    </Page>
  )
}

export default PressKitPage
